import fetchIntercept from 'fetch-intercept';
import { Auth } from '@aws-amplify/auth';

export enum InterceptorId {
  AUTH = 'AUTH',
}

const interceptors: Partial<Record<InterceptorId, () => void>> = {};

const createAuthInterceptor = (logout?: () => void) => {
  const unregister = fetchIntercept.register({
    request: async (url, config) => {
      const isInternalCall =
        url.includes(process.env.NX_AWS_GRAPHQL_ENDPOINT || '') ||
        url.includes(process.env.NX_ADMIN_BE_GRAPHQL_ENDPOINT || '') ||
        url.includes(process.env.NX_AWS_BASE_ENDPOINT || '');
      let newTokenIfNeeded;
      if (isInternalCall) {
        /**
         * Calling currentSession() is enough for refreshing token see more details here
         * https://stackoverflow.com/a/65029659/3522927
         */
        try {
          const session = await Auth.currentSession();
          newTokenIfNeeded = session.getIdToken().getJwtToken();
        } catch {
          if (logout) {
            logout();
          }
        }
      }
      const enhancedConfig = {
        ...config,
        headers: new Headers({
          ...(config?.headers || {}),
          // Add Auth token only when firing a request to internal API
          ...(process.env.NX_AWS_GRAPHQL_ENDPOINT &&
            isInternalCall &&
            newTokenIfNeeded && {
              Authorization: `Bearer ${newTokenIfNeeded}`,
            }),
        }),
      };
      return [url, enhancedConfig];
    },
  });
  interceptors[InterceptorId.AUTH] = unregister;
};

const unregisterInterceptor = (id: InterceptorId) => {
  interceptors[id] && interceptors[id]?.();
};

export { createAuthInterceptor, unregisterInterceptor };
