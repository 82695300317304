import { format, startOfDay } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import { TPeriodOfDay } from './types/TDate';

export const BACKEND_FORMAT = `yyyy-MM-dd'T'hh:mm:ss.SSSX`;

export const NOTIFICATIONS_DATE_FORMAT = `yyyy-MM-dd'T'HH:mm:ss.SSS'Z`;

export const ISO_DATE_FORMAT = 'MM/dd/yyyy';

export const formatISODate = (date: string, resultFormat = ISO_DATE_FORMAT) => {
  return format(new Date(date), resultFormat);
};

export const roundMinutes = (date: Date, to = 15) => {
  const minutes = date.getMinutes() % to === 0 ? date.getMinutes() + 1 : date.getMinutes();
  const minute = Math.ceil(minutes / to) * to;
  const result = new Date(date);
  result.setSeconds(0);
  result.setMinutes(minute);
  result.setMilliseconds(0);
  return result;
};

export const formatDateObject = (date: Date, resultFormat = 'MMMM dd, yyyy') => {
  return format(date, resultFormat);
};

export const isValidDate = (date: string) => date && !isNaN(new Date(date).getTime());

export const formatTableDateTime = (date: string, timeZone?: string) => {
  if (!isValidDate(date)) {
    return date;
  }
  if (!timeZone) {
    return formatISODate(date, 'MMM dd, yyyy hh:mm a');
  }
  return formatISODate(utcToZonedTime(date, timeZone).toString(), 'MMM dd, yyyy hh:mm a');
};

export const formatTableDate = (date: string) => {
  return formatISODate(date, 'MMM dd, yyyy');
};

export const getNowUtc = () => {
  return new Date(new Date().toUTCString().slice(0, -4));
};

/**
 * Morning 4:00-11:59
 * Afternoon 12:00-16:59
 * Evening 17:00-19:59
 * Night 20:00-3:59
 */
export const getPeriodOfDay = (utcDate: Date, timezone: string): TPeriodOfDay => {
  const zonedTime = utcToZonedTime(utcDate, timezone);
  const hours = zonedTime.getHours();
  if (hours >= 20 || hours <= 3) return TPeriodOfDay.NIGHT;
  if (hours >= 17) return TPeriodOfDay.EVENING;
  if (hours >= 12) return TPeriodOfDay.AFTERNOON;
  if (hours >= 4) return TPeriodOfDay.MORNING;

  return TPeriodOfDay.MORNING;
};

export const getIsSameDay = (dateLeft: Date, dateRight: Date) => {
  return startOfDay(dateLeft).getTime() === startOfDay(dateRight).getTime();
};
