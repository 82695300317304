import { FC, ReactElement } from 'react';
import styled from 'styled-components';

import { TBaseProps } from '../../../types/TBaseComponent';
import { device } from '../../../utils/styleUtils';

const StyledSider = styled.div<{ widthSize: number; collapsedWidthSize: number }>`
  transition: width 250ms;
  width: ${({ collapsedWidthSize }) => collapsedWidthSize}px;
  ${device.md} {
    width: ${({ widthSize }) => widthSize}px;
  }
`;

const SiderContent = styled.div<{ headerHeight?: number }>`
  background-color: ${({ theme }) => theme.colors.menuBackground};
  height: calc(100% - ${({ headerHeight }) => headerHeight || 0}px);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

export interface SiderProps extends TBaseProps {
  width: number;
  collapsedWidth: number;
  headerHeight?: number;
  header?: ReactElement;
}

const Sider: FC<SiderProps> = ({ width, collapsedWidth, headerHeight, header, children }) => (
  <StyledSider widthSize={width} collapsedWidthSize={collapsedWidth}>
    {header}
    <SiderContent headerHeight={headerHeight}>{children}</SiderContent>
  </StyledSider>
);

export { Sider };
